import React from "react";
import {Button} from "antd";
import {MailOutlined} from "@ant-design/icons";
import "./Hero.scss"


export const Hero: React.FC = () => {

  return (
    <div className="Hero">
      <div className="Hero__Content">
        <div className="Hero__Title">
          Viviane Hauschildt
        </div>
        <div className="Hero__Subtitle">
          freie Autorin und Texterin aus Hamburg
        </div>
        <div className="Hero__CTA">
          <Button
            className="Hero__CTA__Button"
            icon={<MailOutlined/>}
            size="large"
            type="text"
            href="mailto:viviane.h@uschildt.de?subject=Anfrage"
          >
            Anfrage stellen
          </Button>
        </div>
      </div>
    </div>
  )
}
