import React from "react";
import classNames from "classnames";
import "./Container.scss";

interface ContainerProps extends React.HTMLProps<HTMLDivElement>{}

export const Container: React.FC<ContainerProps> = ({className, children, ...divProps}) => {

  return (
    <div className={classNames("Container", className)} {...divProps}>
      <div className="Container__Content__Wrapper">
        {children}
      </div>
    </div>
  )
}
