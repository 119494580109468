import React from "react";
import {Section} from "./Section";
import {Container} from "./Container";
import "./Footer.scss"
import {Col, Row} from "antd";

export const Footer: React.FC = () => {
  return (
    <Section className="Footer">
      <Container>
        <Row gutter={16}>
          <Col xs={{span: 16, order: 2}} sm={{span: 8, order: 1}}>
            <div>Viviane Hauschildt</div>
          </Col>
          <Col xs={{span: 24, order: 1}} sm={{span: 8, order: 2}}>
            <div className="Footer__Impressum"><a href="/#/impressum">Impressum</a></div>
          </Col>
          <Col xs={{span: 8, order: 3}} sm={{span: 8, order: 3}}>
            <div className="text-right">Juli 2021</div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
