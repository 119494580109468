import React from "react";
import {Hero} from "./Hero";
import {Container} from "./Container";
import {Button, Col, Row, Tag} from "antd";
import {Section} from "./Section";
import {Footer} from "./Footer";
import {MailOutlined} from "@ant-design/icons";

export const Home: React.FC = () => {
  return (
    <div className="pos-relative h-100">
      <Hero/>

      <Section className="bg-gray">
        <Container>
          <Row gutter={16}>
            <Col xs={24} md={6} className="section-title">
              Meine Leistungen
            </Col>
            <Col xs={24} sm={16} md={12}>
              <p>
                Ich bin Viviane Hauschildt, freie Autorin und Texterin in Hamburg und dem südlichen Umland mit den
                Schwerpunkten Ernährung, Gesundheit und Handel. Ob Beiträge für Printmagazine, Websites, Blogs oder
                PR-Materialien – ich schreibe Ihnen den Text, den Sie brauchen.
              </p>
              <p>
                Zu meinem Portfolio gehören Texte aller Art,
                zum Beispiel lebendige Reportagen, emotionale Porträts, sauber recherchierte Fachtexte, Interviews oder
                knackig geschriebene PR- und Produkttexte.
              </p>
            </Col>
            <Col xs={24} sm={8} md={6}>
              <ul className="Tag-List">
                <li><Tag>Reportagen</Tag></li>
                <li><Tag>PR-Texte</Tag></li>
                <li><Tag>Advertorials</Tag></li>
                <li><Tag>B2B</Tag></li>
                <li><Tag>B2C</Tag></li>
                <li><Tag>Interviews</Tag></li>
                <li><Tag>Nährwerte</Tag></li>
                <li><Tag>Analysen</Tag></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-white">
        <Container>
          <Row gutter={16}>
            <Col xs={24} md={6} className="section-title">
              Meine Referenzen
            </Col>
            <Col xs={24} sm={16} md={12}>
              <p>
                Gerne unterstütze ich auch kleine Unternehmen oder
                Solo-Selbstständige bei der Erarbeitung ihrer Texte.
              </p>
              <p>
                Vor Ort bin ich in Hamburg und Umgebung im Einsatz,
                Remote ist natürlich auch überregional kein Problem.
                Gerne besuche ich Sie für ein erstes Kennenlernen vor Ort.
              </p>
            </Col>
            <Col xs={24} sm={8} md={6}>
              <ul className="Tag-List">
                <li><Tag>Women's Health</Tag></li>
                <li><Tag>Men's Health</Tag></li>
                <li><Tag>Runner's World</Tag></li>
                <li><Tag>EDEKA Handelsrundschau</Tag></li>
                <li><Tag>EDEKA Verbund</Tag></li>
                <li><Tag>Klosterhof Mönchsdeggingen</Tag></li>
                <li><Tag>Stefanie Baars Fotografie</Tag></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-gray">
        <Container>
          <Row gutter={16}>
            <Col xs={24} md={6} className="section-title">
              Über mich
            </Col>
            <Col xs={24} sm={16} md={12}>
              <p>
                Privat bin ich: Zwillingsmama von zwei wundervollen Jungs; eine Ehefrau, mit der mein Mann
                meistens ziemlich viel zu lachen hat und Hundemama von einer Labbidame namens Zoe, die schnarcht wie keiner sonst
                im Haus (also der Hund, nicht ich).
              </p>
              <p>
                Ich habe Ökotrophologie an der HAW Hamburg studiert und liebe alles um das Thema (gesunde) Ernährung, Sport und Gesundheit. Ich recherchiere dabei gerne komplexe Themen und bereite dieses für die Leser verständlich auf.
              </p>
              <p>
                Dabei bin ich bin ein kreativer Mensch, der gerne seine eigenen Ideen einbringt und mit anderen gemeinsam etwas auf
                die Beine stellt. Jemand, der auch mal rumspinnt und überlegt, wie man festgefahrene Muster durchbrechen könnte.
                Und auch jemand, der lieber nochmal mehr nachfragt, damit es keine Unklarheiten gibt. Ich arbeite schnell,
                zuverlässig und diszipliniert, wenn es darum geht ein Ziel zu erreichen.
              </p>
            </Col>
            <Col xs={24} sm={8} md={6}>
              <div className="viviane_zoe" />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="bg-white">
        <Container>
          <div className="d-flex flex-justify-center">
            Ich freue mich, Ihnen genau den Text zu schreiben, den Sie brauchen.
          </div>
          <div className="d-flex flex-justify-center">
            <Button
              className="Outlined-Button mt-2"
              icon={<MailOutlined/>}
              size="large"
              type="text"
              href="mailto:viviane.h@uschildt.de?subject=Anfrage"
            >
              Kontaktieren Sie mich direkt
            </Button>
          </div>
        </Container>
      </Section>

      <Footer />
    </div>
  )
}
