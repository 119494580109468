import React from "react";
import classNames from "classnames";
import "./Section.scss";

interface SectionProps extends React.HTMLProps<HTMLDivElement>{}

export const Section: React.FC<SectionProps> = ({children, className, ...divProps}) => {

  return (
    <div className={classNames("Section", className)} {...divProps}>
      {children}
    </div>
  )
}
