import React from 'react';
import './App.scss';
import { Impressum } from "./common/Impressum";
import {Switch, Route, HashRouter} from "react-router-dom";
import {Home} from "./common/Home";

const App: React.FC = () => {

  return (
    <HashRouter>
      <Switch>
        <Route path="/impressum">
          <Impressum />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
